import React, { useEffect, useState } from "react";
import { Header } from "../features/Header";
import './ProductManagement.css';
import axios from 'axios';
import { toast } from "react-toastify";


export const ProductManagement = ()=>{
    const [products, setProducts] = useState([]);

    const url = `https://node-api.pigagoodlook.com/api/`;

    useEffect(()=>{
        axios.get(url + 'getAllProducts')
        .then((res)=>setProducts(res.data))
        .catch((err)=>{
            if(err.request.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('superadmin')
                window.location.pathname = '/superadmin'
            }
        })
    }, [url]);


    const handleProductAcceptance = (id)=>{
        axios.get( url + `updateProductStatus/${id}`)
        .then((res)=>
            {
                toast.success("You have accepted the product")})
        .catch((err)=>{
            if(err.code.includes('ERR_NETWORK')){
                toast.error('You are offline. Check you network and try again');
            }else{
                toast.error("There is an issue with accepting the product. Try again.")
            }
        })
    }

    const handleProductRejection = (id)=>{
        axios.get(url + `updateProductReject/${id}`)
        .then((res)=>
            {
                toast.success("You have Rejected the product")})
        .catch((err)=>{
            if(err.code.includes('ERR_NETWORK')){
                toast.error('You are offline. Check you network and try again');
            }else{
                toast.error("There is an issue with rejecting the product. Try again.")
            }
        })
    }

    const handleReprocessImage = (item)=>{
        toast.success("Started reprocessing the images")
        axios.post(url + 'reprocessImages', { Images: [item.image1, item.image2, item.image3] })
        .then((resp)=>{
            const response = resp.data;
            console.log(response);
        }).catch((err)=>{
            if(err.code.includes('ERR_NETWORK')){
                toast.error('You are offline. Check you network and try again');
            }else{
                toast.error("There is an issue with reprocessing the product. Try again later.")
            }
        });
    }        
    
    return(
        <div className="ProductManagementContainer">
            <Header />
            <div className="ProductManagement">
                {
                    products.map((item, i)=>{
                        if(item.status === 'posted'){ 
                            return(
                                <div key={i}>
                                    <div className="ImgContainer">
                                        <img src={url + item.image1} alt="Product pic 1"/>
                                        <img src={url + item.image2} alt="Product pic 2"/>
                                        <img src={url + item.image3} alt="Product pic 3"/>
                                    </div>
                                    <div>
                                        <p>Gender: {item.gender}</p>
                                        <p>Size: {item.size}</p>
                                        <p>Type: {item.type}</p>
                                        <p>Phone {item.phone}</p>
                                    </div>
                                    <div className="ActionBar">
                                        <button onClick={()=>{handleProductAcceptance(item.id)}}>Accept</button>
                                        <button onClick={()=>{handleProductRejection(item.id)}}>Reject</button>
                                        <button onClick={()=>{handleReprocessImage(item)}}>Reprocess</button>
                                    </div>
                                </div>
                            )
                        }
                        return null;
                    })
                }
            </div>
        </div>
    )
}