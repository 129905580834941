import {
    Outlet,
    Navigate
} from 'react-router-dom'

const SuperPrivateRoutes = () => {
    const superAdmin = localStorage.getItem("superadmin");

    let auth = { 'token': superAdmin }
    return (
        auth.token ?
            <Outlet /> :
            <Navigate to="/superadmin" />
    )
}

export default SuperPrivateRoutes;
