import React, { useState, useEffect } from "react";
import "./ImagesDisplayer.css";

export const ImagesDisplayer = ({ files }) => {
    const [images, setImages] = useState([]);

    useEffect(()=>{
        for(let i = 0; i < files.length; i++){
            const file = files[i];
            const imageUrl = URL.createObjectURL(file);
            setImages([...images, imageUrl])
        }
    }, [files])
    

    return (
        <main className="ImageDisplayer">
            {images.map((src, index) => {
               return(
                <img key={index} src={src} alt={`upload-${index}`} />
            )
            })}
        </main>
  );
};


