import React from "react";
import './About.css';
import { Header } from "./Header";
import { IoCall } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";

export const About = ()=>{
    const whyUs = [
        {
            heading: 'Affordability',
            subheading : 'Our platform offers unbeatable prices on high-quality, gently-used items, allowing customers to stretch their budget further and afford more of what they love without breaking the bank.'
        },
        {
            heading: 'Uniqueness',
            subheading : `With a diverse range of second-hand treasures curated by trusted sellers from around the globe, customers have access to one-of-a-kind fashion pieces and collectibles that can't be found anywhere else, adding a touch of individuality to their wardrobe or home.`
        },
        {
            heading: 'Sustainability',
            subheading : 'By embracing second-hand shopping, customers play a vital role in reducing waste and minimizing their environmental footprint. Each purchase contributes to a more sustainable future by giving pre-loved items a new lease on life instead of ending up in landfills.'
        },
        {
            heading: 'Convenience',
            subheading : `Our intuitive platform and seamless browsing features make the shopping experience effortless and enjoyable. Customers can shop anytime, anywhere, and find exactly what they're looking for with just a few clicks, saving valuable time and effort.`
        },
        {
            heading: 'Community',
            subheading : `Joining our vibrant community of like-minded shoppers and sellers offers customers a sense of belonging and connection. Whether they're sharing style tips, swapping stories, or supporting small businesses, our platform fosters a sense of camaraderie and mutual support that enhances the overall shopping experience.`
        },
    ];

    return(
        <main className="AboutContainer">
            <Header />
            <div id="uncover">
                Uncover Hidden Gems
            </div>
            <div className="welcome">
                <p>Your Ultimate Hub for Pre-Loved Fashion Finds!</p>
                <p>At Piga Goodlook, we pride ourselves on revolutionizing the online shopping experience for savvy consumers and passionate sellers alike.
                    Our platform stands out as the ultimate destination for second-hand treasures, offering a diverse array of high-quality, gently-used items curated by trusted sellers from around the globe. With a keen eye for style and a commitment to sustainability, we empower individuals to discover unique fashion pieces while reducing their environmental footprint.
                    Our seamless interface and intuitive browsing features make finding your next statement piece effortless, while our secure transaction process ensures peace of mind with every purchase. 
                    Whether you're on the hunt for vintage classics, trendy accessories, or one-of-a-kind collectibles, piga GoodLook is your go-to source for affordable luxury and eco-conscious shopping.
                    Join our vibrant community today and embark on a journey of endless discovery, where every purchase tells a story and contributes to a more sustainable future.</p>
            </div>
            <div className="whyus">
                <p style={{textDecoration: 'underline'}}>Why Us?</p>
                {
                    whyUs.map((item, i)=>{
                        return(
                            <div key={i}>
                                <p>{item.heading}</p>
                                <p>{item.subheading}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div>
                <div className="moreInfo">
                    <p id="heading">Delivery</p>
                    <p>We are committed to providing our customers with the best possible shopping experience, which includes ensuring timely delivery of their orders. To streamline our operations and maintain efficiency, we kindly request that all purchases be paid for in advance. This allows us to promptly process orders, prepare items for shipment, and coordinate with our logistics partners to deliver them to your doorstep in a timely manner. By completing payment at the time of purchase, you help us expedite the fulfillment process and minimize any potential delays, ensuring that you receive your desired items as quickly as possible. We appreciate your cooperation in this matter and remain dedicated to delivering exceptional service with every order.</p>
                </div>
                <div className="moreInfo">
                    <p id="heading">Team</p>
                    <p>Our team is a dynamic collaboration between our dedicated company team and carefully vetted sellers. Together, we curate a diverse range of quality second-hand finds, ensuring every item meets our high standards. With unique expertise and passion, we create an inclusive community where customers can shop confidently, supporting both our company and talented sellers.</p>
                </div>
                <div className="moreInfo">
                    <p id="heading">Contact Us</p>
                    <p><IoCall size={30}/>0745303896</p>
                    <p><MdOutlineMail size={30}/>pigagoodlook@gmail.com</p>
                </div>
            </div>
        </main>
    )
}