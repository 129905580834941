import React, {useEffect, useState} from "react";
import {Header} from '../../src/features/Header';
import './Dashboard.css';
import axios from "axios";

export const Dashboard = () =>{
    const[sold, setSold]=useState('');
    const [balance, setBalance] = useState('');
    const [uploaded, setUploaded]=useState('');
    const [made, setMade]=useState('');
    const token = localStorage.getItem('token');
    const user = localStorage.getItem("user");

    useEffect(()=>{
        axios.get(`https://node-api.pigagoodlook.com/api/soldProducts/${user}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res)=>{
            setSold(res.data.data)
        }).catch(
            (err)=>{
                if(err.request.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('admin')
                    window.location.pathname = '/admin'
                }
            }
        )
    }, [token, user]);

    useEffect(()=>{
        axios.get(`https://node-api.pigagoodlook.com/api/availableBalance/${user}`,  {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res)=>{setBalance(res.data.balance)})
        .catch((err)=>{
            if(err.request.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('admin')
                window.location.pathname = '/admin'
            }
        })
    }, [token, user]);

    useEffect(()=>{
        axios.get(`https://node-api.pigagoodlook.com/api/products/${user}`,  {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res)=>setUploaded(res.data.length))
        .catch((err)=>{
            if(err.request.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('admin')
                window.location.pathname = '/admin'
            }
        })
    }, [token, user]);

    useEffect(()=>{
        axios.get(`https://node-api.pigagoodlook.com/api/total-withdrawals/${user}`,  {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res)=>setMade(res.data.result))
        .catch((err)=>{
            if(err.request.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('admin')
                window.location.pathname = '/admin'
            }
        })
    }, [token, user]);

    // useEffect(()=>{
    //     axios.get('https://node-api.pigagoodlook.com/api/totalmade/51')
    //     .then((res)=>console.log(res.data))
    //     .catch((err)=>console.log(err))
    // });
    
    return(
        <div className="DashboardContainer">
            <Header />
            <main className="DashboardContent">
                <div>
                    <p>Total Products uploaded - {uploaded}</p>
                </div>
                <div>
                    <p>Total Products Sold : {sold}</p>
                </div>
                <div>
                    <p>Made: Kes.{made}</p>
                </div>
                <div>
                    <p>Available Balance - Kes. {balance}</p>
                </div>
            </main>
        </div>
    )
}