import React, { useEffect, useState } from "react";
import { Header } from "../features/Header";
import './UserManagement.css';
import { IoIosCloseCircle } from "react-icons/io";
import { SiNike } from "react-icons/si";
import axios from "axios";
import { toast } from "react-toastify";

export const UserManagement = () =>{
    const [users, setUsers] = useState([]);

    const url = `https://node-api.pigagoodlook.com/api/`;

    const handleSubscriptions = (id) => {
        const confirm = window.confirm("Are you sure you want to change the settings for this user?")

        if(confirm){
            axios.get(url + `updateUserSubscribedStatus/${id}`)
            .catch((err)=>{
                if(err.request.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('superadmin')
                    window.location.pathname = '/superadmin'
                }
                if(err.code.includes('ERR_NETWORK')){
                    toast.error('You are offline. Check you network and try again');
                }else{
                    toast.error("Error changing user setting");
                }
            });
        }
        
    }

    const handleRejectionOfSubscriptions = (id) => {
        const confirm = window.confirm("Are you sure you want to change the settings for this user?")

        if(confirm){
            axios.get(url + `updateUserNotSubscribedStatus/${id}`)
            .catch((err)=>{
                if(err.request.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('superadmin')
                    window.location.pathname = '/superadmin'
                }
                if(err.code.includes('ERR_NETWORK')){
                    toast.error('You are offline. Check you network and try again');
                }else{
                    toast.error("Error changing user setting");
                }
            })
        }
        
    }

    useEffect(()=>{
        axios.get(url + 'getAllUsers')
        .then((res)=>{
            setUsers(res.data)
        }).catch((err)=>{
            if(err.request.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('superadmin')
                window.location.pathname = '/superadmin'
            }
            if(err.code.includes('ERR_NETWORK')){
                toast.error('You are offline. Check you network and try again');
            }else{
                toast.error("Refresh this page.");
            }
        })
    }, [url])
    
    return(
        <main className="UserManagementContainer">
            <Header />
            <div className="UserSearchContainer">
                    <input placeholder="Search number, name"/>
                    {
                        users.map((user)=>{
                            return(
                                <div className="UserList">
                                    <p>{user.username.toUpperCase()}</p>
                                    {
                                        user.subscribed === 0 ? <SiNike style={{cursor: 'pointer'}} size={40} onClick={()=>{handleSubscriptions(user.id)}}/> : <IoIosCloseCircle style={{cursor: 'pointer'}} size={40} onClick={()=>{handleRejectionOfSubscriptions(user.id)}}/>
                                    }
                                </div>
                            )
                        })
                    }
            </div>
        </main>
    )
}