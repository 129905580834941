import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LandingPage } from './components/LandingPage';
import { Admin } from './admin/Admin';
import { UploadProduct } from './components/Upload';
import { Uploaded } from './components/Uploaded';
import { Payments } from './components/Payments';
import PrivateRoutes from './features/ProtectedRoutes';
import SuperPrivateRoutes from './features/SuperProtectedRoutes';
import { SuperAdmin } from './superadmin/superAdmin';
import { UserManagement } from './superadmin/UserManagement';
import { ProductManagement } from './superadmin/ProductManagement';
import { Orders } from './components/OrdersContainer';
import { Shopping } from './components/Shopping';
import { Cart } from './components/Cart';
import { Register } from './features/Register';
import { PageNotFound } from './components/PageNotFound';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';

import { About } from './features/About';


function App() {
  const [cart, setCart]=useState([]);
  return (
    <main className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<LandingPage />} /> 
            <Route path="admin" element={<Admin />} />
            <Route path="superadmin" element={<SuperAdmin />} />
            <Route element={<SuperPrivateRoutes />}>
              <Route path="superadmin/users" element={<UserManagement />} />
              <Route path="superadmin/products" element={<ProductManagement />} />
              <Route path="superadmin/orders" element={<Orders />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path='admin/upload' element={<UploadProduct />}/>
              <Route path='admin/uploaded' element={<Uploaded />}/>
              <Route path='admin/payments' element={<Payments />}/> 
            </Route>
            <Route path="about" element={<About />} />
            <Route path="register" element={<Register />} />
            <Route path="shopping" element={<Shopping setCart={setCart} cart={cart}/>} />
            <Route path="cart" element={<Cart cart={cart} setCart={setCart}/>} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
      </BrowserRouter>
      <ToastContainer />
      </header>
    </main>
  );
}

export default App;
