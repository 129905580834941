import React from "react";
import './FeatureContainers.css';

export const FeatureContainers = ({image, icon, heading, subheading})=>{
    return(
        <div className="FeatureContainer">
            <img src={image} alt={icon}/>
            <p id="heading">{heading}</p>
            <p id="subheading">{subheading}</p>
        </div>
    )
}