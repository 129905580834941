import React, { useState, useEffect } from "react";
import { Header } from "../features/Header";
import './Cart.css';
import { IoCloseCircle } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { toast } from "react-toastify";
import axios from 'axios';

export const Cart = ({ cart, setCart }) => {
    const [cartView, setCartView] = useState(true);
    const [location, setLocation] = useState(null);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [phoneErr, setPhoneErr] = useState('');
    const [confirm, setConfirm] = useState('');
    const [pop, setPop] = useState('none');

    const [delivery, setDelivery] = useState(0);

    const url = 'https://node-api.pigagoodlook.com/api/';

    const totalPrice = (array) => {
        return array.reduce((total, item) => total + item.price, 0);
    };

    const cartIds = []

    cart.forEach(element => {
        cartIds.push(element.id);
    });

    const handleUpload = () => {
        var err = false;

        if (delivery === 0) {
            err = true;
            toast.success("Kindly accept the location to calculate the delivery fee");
        } else {
            err = false;
        }

        if (!name) {
            err = true;
            setNameErr('2px solid red');
        } else {
            setNameErr('');
        }

        const regex = /^(07|01|\+2547)\d{8}$/;

        if (!regex.test(phone)) {
            err = true;
            setPhoneErr('2px solid red');
        } else {
            setPhoneErr('');
        }
        if (err) {
            if (nameErr) {
                toast.error("What can we call you")
            }
            if (phoneErr) {
                toast.error("Check your phone number")
            }
        } else {
            console.log({ phoneNumber: phone, productsIds: [[cartIds]], amount: totalPrice(cart), deliveryFee: delivery, totaAmount: totalPrice(cart) + delivery, fullName: name })
            axios.post(url + 'stk', { phoneNumber: phone.startsWith('0') ? phone.replace('0', '254') : phone.replace(''), productsIds: cartIds, amount: `${totalPrice(cart)}`, deliveryFee: delivery, totalAmount: totalPrice(cart) + delivery, fullName: name })
            .then((response)=>{
                setPop('');
                toast.success("Handling payments, kindly input mpesa pin in your phone");
            })
            .catch((err)=>{
                toast.error("There is an error on our side. Try again kindly.")
                window.location.href = '/shopping';
            })
        }
    }

    const handleConfirm = () => {
        if (!confirm) {
            toast.error("Input code")
        } else {
            axios.post(url + 'check-payment', {mpesaCode : confirm})
            .then(()=>{
                toast.success("Payment Successful! Thanks for shopping with us.");
                setTimeout(()=>{
                    window.location.href = '/shopping';
                }, 3000);
            })
            .catch((err)=>{
                if(err.request.status === 401){
                    toast.error("There is an issue on the server");
                }else{
                    toast.error("The code has been used before");
                }
            })
        }
    }

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            })
            toast.success("Thanks for accepting the location");
        }, (err) => {
            toast.error("Kindly try a different web browser");
        })
    };

    function haversineDistance(lat1, lon1, lat2, lon2) {
        const toRadians = angle => (angle * Math.PI) / 180;

        const R = 6371; // Radius of the Earth in kilometers
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers

        return distance;
    }

    useEffect(() => {
        if (location) {
            const distance = haversineDistance(-1.286389, 36.817223, location.latitude, location.longitude);
            const roundedDistance = Math.round(distance);

            if (roundedDistance < 25) {
                setDelivery(3);
            } else if (roundedDistance < 50) {
                setDelivery(400);
            } else if (roundedDistance < 75) {
                setDelivery(500);
            } else if (roundedDistance < 100) {
                setDelivery(600);
            } else if(roundedDistance < 200){
                setDelivery(700);
            } else {
                setDelivery(1000);
            }
        }
    }, [location]);

    return (
        <div>
            <Header cart={cart} />
            {
                cartView ?

                    <div className="CartContainer">
                        <div className="itemsContainer">
                            {
                                cart.map((item, i) => {
                                    console.log(item.status)
                                    return (
                                        <div key={i} className="cartItem" style={{ border: item.status !== 'approved' ? "3px solid red" : "1px solid black" }}>
                                            <img src={url + item.image1} alt={item.name} />
                                            <p>{item.price}</p>
                                            <IoCloseCircle style={{ cursor: 'pointer' }} onClick={() => { setCart(cart = cart.filter(value => value.id !== item.id)) }} size={30} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="checkOut">
                            <p>Cost: {totalPrice(cart)}</p>
                            <button onClick={() => { cart.length > 0 ? setCartView(false) : window.confirm('Kindly enter items to cart') }}>Check Out</button>
                        </div>
                    </div>


                    :

                    <div className="CheckOutContainer">
                        <div>
                            <p>Name</p>
                            <input style={{ border: nameErr }} value={name} placeholder="What do we call you?" onChange={(e) => { setName(e.target.value) }} />
                        </div>
                        <div>
                            <p>Phone Number</p>
                            <input style={{ border: phoneErr }} value={phone} placeholder="Your Mpesa number" onChange={(e) => { setPhone(e.target.value) }} />
                        </div>
                        <div>
                            {
                                location ? null : <div onClick={() => { getLocation() }} style={{ display: 'flex', alignItems: 'center', color: 'red ', cursor: 'pointer' }}>Accept location <FaLocationDot size={30} style={{ cursor: 'pointer' }} /></div>
                            }
                            <p>Products Cost: {totalPrice(cart)}</p>
                            <p>Delivery Fee: {delivery}</p>
                            <p>Total Amount: {totalPrice(cart) + delivery}</p>
                        </div>
                        <button onClick={() => { handleUpload() }}>Pay</button>
                        <div className="confirmPayments" style={{ display: pop }}>
                            <p>Confirm Payments</p>
                            <input style={{ width: "250px" }} value={confirm} onChange={(e) => { setConfirm(e.target.value) }} placeholder="Mpesa confirmation code" />
                            <button onClick={() => { handleConfirm() }} style={{ width: 'auto' }}>Confirm</button>
                        </div>
                    </div>
            }

        </div>
    )
}
