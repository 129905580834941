import React, { useState } from "react";
import './Register.css';
import { toast } from "react-toastify";
import axios from "axios";

export const Register = ()=>{
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [usernameErr, setUsernameErr]=useState('');
    const [emailErr, setEmailErr]=useState('');
    const [passwordErr, setPasswordErr]=useState('');
    const [phoneErr, setPhoneErr]=useState('');
    const [confirmErr, setConfirmErr]=useState('');
    const [registering, setRegistering] = useState(true)

    const handleRegister = ()=>{
        var err = false;
        if(!username){
            setUsernameErr('2px solid red');
            err = true;
        }else{
            setUsernameErr('');
            err = false;
        }
        const emailRegex = /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/
        if(!emailRegex.test(email)){
            setEmailErr('2px solid red');
            err = true;
        }else{
            setEmailErr('');
            err = false;
        }
        if(password < 5 || password > 10){
            setPasswordErr('2px solid red');
            err = true;
        }else{
            setPasswordErr('');
            err = false;
        }
        const phoneRegex = /^(07|01|\+2547)\d{8}$/;

        if(!phoneRegex.test(phone)){
            setPhoneErr('2px solid red');
            err = true;
        }else{
            setPhoneErr('');
            err = false;
        }

        if((password) && (password !== confirm)){
            setConfirmErr('2px solid red');
            err = true;
        }else if(!confirm){
            setConfirmErr('2px solid red');
            err = true;
        }
        else{
            setConfirmErr('');
            err = false;
        }
        if(!err){
            setRegistering(false);
            axios.post('https://node-api.pigagoodlook.com/api/register', {
                username: username , email: email, phoneNumber: phone, password: password, location: ''
            }).then((res)=>{
                setRegistering(true);
                if(res.status === 200){
                    toast.success("You have successfully created an account with us. Log In for more")
                    setTimeout(()=>{
                        window.location.pathname = '/admin';
                    }, 3000)
                }
            }).catch((err)=>{
                setRegistering(true);
                if(err.request.status === 409){
                    toast.error("Those credentials are being used by another account!");
                }else{
                    toast.error("There is an error on our side. Kindly try again later");
                }
            })
        }
    }
    return(
        <main className="RegisterContainer">
            <div className="Register">
                <div>
                    <label>Username</label>
                    <input value={username} onChange={(e)=>{setUsername(e.target.value)}} style={{border: usernameErr}} type="text" name="username" placeholder="Input username"/>
                </div>
                <div>
                    <label>Phone Number</label>
                    <input type="text" value={phone} onChange={(e)=>{setPhone(e.target.value)}} style={{border: phoneErr}} placeholder="Input phone number"/>
                </div>
                <div>
                    <label>Email</label>
                    <input type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} style={{border: emailErr}}  name="email" placeholder="Input email"/>
                </div>
                <div>
                    <label>Password</label>
                    <input type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} style={{border: passwordErr}} placeholder="Input password"/>
                </div>
                <div>
                    <label>Confirm Password</label>
                    <input type="password" value={confirm} onChange={(e)=>{setConfirm(e.target.value)}} style={{border: confirmErr}} placeholder="Confirm password"/>
                </div>
                <button onClick={()=>{handleRegister()}}>{registering ? "Register" : "Registering..." }</button>
            </div>
        </main>
    )
}