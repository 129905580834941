import React from "react";
import { IoHome } from "react-icons/io5";

export const PageNotFound = ()=>{
    return(
        <div style={{height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <p style={{fontSize: "24px", fontWeight: '800'}}>This page does not exist.</p>
            <p style={{display: 'flex', flexDirection: "row", alignItems: 'center', marginTop: '30px'}}>Go back to <IoHome onClick={()=>{window.location.href = '/'}} style={{cursor: 'pointer'}} size={30}/></p>
        </div>
    )
}