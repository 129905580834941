import React, { useEffect, useState } from "react";
import './Upload.css';
import { Header } from "../features/Header";
import { Select } from "../features/Select";
import { ImagesDisplayer } from "../features/ImagesDisplayer";
import { toast } from "react-toastify";
import axios from "axios";

export const UploadProduct = ()=>{
    const [image, setImage] = useState(true);
    const [files, setFiles]=useState([]);
    const [type, setType]=useState('');
    const [gender, setGender]=useState('');
    const [size, setSize]=useState('');
    const [name, setName]=useState('');
    const [price, setPrice]=useState('');
    const [loading, setLoading] = useState(false);
    const user_id = localStorage.getItem("user");;
    const euShoeSizes = [37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47];
    const clothingSizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];
    const token = localStorage.getItem('token');
    
    const dropDownOptions = type === 'Shoes' ? euShoeSizes : clothingSizes;

    useEffect(()=>{
    }, [type])

    const formData = new FormData();

    formData.append('name', name);
    formData.append('type', type);
    formData.append('gender', gender);
    formData.append('size', size);
    formData.append('price', price);
    formData.append('picha', files[0]);
    formData.append('picha', files[1]);
    formData.append('picha', files[2]);
    formData.append('user_id', user_id);

    const handleNext = ()=>{
        if(files.length !== 3 || !type){
            toast.error("Select type and Input Three Images")
        }else{
            setImage(0);
        }
    }

    const handleUpload = ()=>{
        try{
            setLoading(true)
            const regex = /^[0-9]+$/;
            if(!gender || !size || !name || name.length > 20){
                toast.error("Choose the correct credentials");
            }else if(regex.test(price)){
                toast.success("Uploading Product");
                axios.post('https://node-api.pigagoodlook.com/api/uploads', formData, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then(()=>{
                    setLoading(false);
                    setFiles([]);
                    setType('');
                    setImage(true);
                    setGender('');
                    setSize('');
                    setName('');
                    setPrice('');
                    toast.success("Product Uploaded successfully");
                })
                .catch((err)=>{
                    console.log(err);
                    if(err.request.status === 401){
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        localStorage.removeItem('admin');
                        window.location.pathname = '/admin'
                    }else{
                        setLoading(false)
                        toast.error("Product Upload failed. Kindly try again later.");
                    }
                })
            }else{
                setLoading(false)
                toast.error("Choose the correct price format");
            }}
        catch(err){
            console.log(err);
            setLoading(false)
            toast.error("Try again, there is an issue with the server")
    }}

    return(
        <main className="UploadContainer">
            <Header />
            <section className="Upload">
                {
                    image

                    ?

                    <div className="ImagesUpload">
                        <Select name="Select type" setValue={setType} dropDown={['Jackets', "Shoes", "Bags"]} color='white' font='black'/>
                        <div className="UploadButton">
                           {
                            files.length === 3 ? 
                            
                            null
                            
                            : 
                            <>
                            <label htmlFor="fileInput" style={{padding: '10px', borderRadius: '5px', backgroundColor: `rgb(52, 152, 219)`, color: "white", cursor: "pointer"}}>Select {files.length === 0 ? "Top" : files.length === 1 ? "Side" : "Bottom"} Image</label>
                            <input id="fileInput" type="file" name="picha" accept=".png, .jpg, .jpeg" style={{display: "none"}}  onChange={
                                (e)=>{
                                    const selectedFiles = Array.from(e.target.files);
                                    const images = selectedFiles.filter(file => file.type.startsWith('image/'))
                                    setFiles(prevFiles => [...prevFiles, ...images].slice(0, 3));
                             }}></input>
                            </>
                           }
                        </div>
                        <ImagesDisplayer files={files}/>
                        <button onClick={()=>handleNext()}>Next Page</button>
                    </div>

                    :
                    <div className="Upload">
                        <div className="ImagesUpload">
                            <div>
                                <label>name</label>
                                <input type="text" id="name" name="name" onChange={(e)=>{setName(e.target.value)}} />
                                <article style={{ textAlign: 'right', paddingTop: 10}}>{name.length}/20</article>
                            </div>
                            <div>
                                <label>Price</label>
                                <input type="text" id="text" name="text" onChange={(e)=>{setPrice(e.target.value)}}/>
                            </div>
                            <Select setValue={setGender} name='Gender' dropDown={['Male', "Female", "Unisex"]} color='white' font='black'/>
                            <Select setValue={setSize} name='Size' dropDown={dropDownOptions} color='white' font='black'/>
                            <button onClick={()=>{handleUpload()}}>{loading ? `Uploading...` : `Upload Product`}</button>  
                        </div>
                    </div>
                }
            </section>
        </main>
    )
}
