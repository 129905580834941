import React, {useState} from "react";
import './LogIn.css';
import axios from 'axios';

import {  toast } from 'react-toastify';

export const LogIn = ({setLogIn})=>{
    const [email, setEmail]=useState('');
    const [password, setPassword]=useState('');
    const [emailErr, setEmailErr]=useState('');
    const [passwordErr, setPasswordErr]=useState('');
    const [loading, setLoading]=useState(false);

    const handleLogin = ()=>{
        if(password.length < 4){
            setPasswordErr('2p solid red');
        }else{
            setPasswordErr('');
        }

        const emailRegex = /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/
        emailRegex.test(email) ? setEmailErr('') : setEmailErr('2px solid red');

        if(emailErr || passwordErr || password.length < 4 || email.length < 4){
            toast.error("Check your credentials");
        }else{
            setLoading(true);
            axios.post('https://node-api.pigagoodlook.com/api/login', {
                email: email, password: password
            }).then((resposnse)=>{
                if(window.location.pathname.startsWith('/admin') && resposnse.status === 200 && resposnse.data.user.subscribed === 1 && resposnse.data.user.role === 'admin'){
                    toast.success("Logged in successfully");
                    localStorage.setItem("admin", true);
                    localStorage.setItem("token", resposnse.data.token);
                    localStorage.setItem('user', resposnse.data.user.id);
                    setLogIn(true);
                    setLoading(true);
                    console.log(resposnse)
                }else if(window.location.pathname.startsWith('/superadmin') && resposnse.status === 200 && resposnse.data.user.subscribed === 1 && resposnse.data.user.role === 'superadmin'){
                    setLogIn(true);
                    setLoading(true);
                    toast.success("Logged in successfully");
                    localStorage.setItem("superadmin", true);
                    localStorage.setItem("token", resposnse.data.token);
                    localStorage.setItem('user', resposnse.data.user.id);
                }
                else if(resposnse.data.user.subscribed === 0){
                    toast.error("You are not subscribed");
                    setLoading(true);
                }
                else{
                    toast.error("You are not authorized");
                    setLoading(true);
                }
            }).catch((err)=>{
                toast.error("Check your credentials");
                console.log(err);
                setLoading(false);
            })
        }
    }

    return(
        <main className="LogInContainer">
            <form className="LogInForm">
                <div>
                    <label>Email</label>
                    <input type="email" id="email" name="email" value={email} style={{border: emailErr}} onChange={(e)=>{
                        setEmail(e.target.value);
                    }}/>
                </div>
                <div>
                    <label>Password</label>
                    <input type="password" id="Password" name="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                </div>
                {
                    loading ?  <input type="submit" value="Logging in" onClick={(e)=>{
                        e.preventDefault();
                        handleLogin();
                    }}></input> :  <input type="submit" value="Log in" onClick={(e)=>{
                        e.preventDefault();
                        handleLogin();
                    }}></input>
                }
            </form>
        </main>
    )
}