import React, {useState} from "react";
import './superAdmin.css';
import { Header } from "../features/Header";
import { LogIn } from "../features/LogIn";

export const SuperAdmin = ()=>{
    const [loggedIn, setLoggedIn] = useState(false);

    return(
        <div>
        {
           loggedIn ? 

            (
            <main>
                <Header />
                <div className="SuperAdmin">
                    <div>
                        Total orders delivered
                    </div>
                    <div>
                        Amount Made
                    </div>
                    <div>
                        Pending deliveries
                    </div>
                    <div>
                        Products Pending approval
                    </div>
                    <div>
                        Number of Subscribed users
                    </div>
                </div>
            </main>
            ) 
            : 
            
            <LogIn setLogIn={setLoggedIn}/>
           
        }
        </div>
    )
}