import React from "react";
import './Footer.css';

export const Footer = ()=>{
    const time = new Date();
    
    return(
        <footer className="FooterContainer">
            <p>Terms and Conditions</p>
            <p>pigagoodlook@{time.getFullYear()}.</p>
            <span>All rights reserved.</span>
        </footer>
    )
}