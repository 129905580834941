import './Select.css';
import React, { useState } from 'react';
import { FaAngleDown } from "react-icons/fa";

export const Select = ({name, dropDown, color, font, setValue})=>{
    const [drop, setDrop]=useState('none');
    const [replace, setReplace]=useState('');
    return(
        <section className='SelectContainer' style={{backgroundColor: color}}>
            <div onClick={()=>{setDrop(drop === 'none' ? '' : 'none')}} className='selectDisplay'><p style={{color: font}}> {replace ? replace : name }</p><FaAngleDown size={20}/></div>
            <section className='selectDropDown' style={{display: drop}}>
                {
                    dropDown.map((Item, i)=>{
                        return(
                            <p key={i} onClick={()=>{setValue(Item);setDrop('none'); setReplace(Item)}}>
                                {Item}
                            </p>
                        )
                    })
                }
            </section>
        </section>
    )
}