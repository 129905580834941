import React, { useEffect, useState } from "react";
import { Header } from "../features/Header";
import './Uploaded.css';
import axios from 'axios';
import { toast } from "react-toastify";

export const Uploaded = ()=>{
    const[products, setProducts]=useState([]);
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');


    useEffect(()=>{
        axios.get(`https://node-api.pigagoodlook.com/api/products/${user}`, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        })
        .then(
            (res)=>{
                setProducts(res.data);
            })   
        .catch((err)=>{
            if(err.request.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('admin');
                window.location.pathname = '/admin'
            }
        })
    }, [user, token]);

    

    return(
        <div className="UploadedContainer">
            <Header />
            <main className="ProductViewContainer">
                {
                    products.map((item, i)=>{
                        return(
                            <div key={i}>
                                <img src={`https://node-api.pigagoodlook.com/api/${item.image1}`} alt="Product"/>
                                <p>{item.name}</p>
                                {
                                    item.status === 'posted' ? <button onClick={()=>{
                                        axios.get(`https://node-api.pigagoodlook.com/api/updateProductReject/${item.id}`)
                                        .then(()=>{
                                            toast.success("Product has been removed successfully");
                                        })
                                        .catch(()=>{
                                            toast.error("Product has not been removed")
                                        })
                                    }}>Remove</button> : <p>{item.status}</p>
                                }
                            </div>
                        )
                    })
                }
            </main>
        </div>
    )
}