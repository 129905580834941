import React from 'react';
import './LandingPage.css';
import { Header } from '../features/Header';
import productIMage from '../assets/images/pigalooklogo.jpg';
import { FaArrowRight } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FeatureContainers } from '../features/FeatureContainers';
import SearchIcon from '../assets/icons/searchIcon.png';
import CartIcon from '../assets/icons/cartIcon.png';
import ConvinienceIcon from '../assets/icons/convenienceIcon.png';
import paymentsIcon from '../assets/icons/paymentsIcon.png';
import { Footer } from '../features/Footer';

export const LandingPage = ()=>{
    const handleRedirect = ()=>{
        window.location.href = '/shopping';
    } 

    const features = [{
        image: SearchIcon,
        heading: "Authentic finds.",
        subheading: "Get high quality thrifted shoes, jackets and bags.",
    },
    {
        image: CartIcon,
        heading: "Hassle-free shopping.",
        subheading: "You don't need an account to shop with us.",
    }, 
    {
        image: ConvinienceIcon,
        heading: "Convinience.",
        subheading: "Shop and get your items delivered on time.",
    }, 
    {
        image: paymentsIcon,
        heading: "Online Payments.",
        subheading: "We are Currently using Mpesa only for payments.",
    }]

   return(
    <main className='LandingPageContainer'>
        <Header />
        <section className='landingPageWelcome'>
            <div className='landingPageInfo'>
                <p id='welcome'>Elevate your style with ease</p>
                <p id='moreOnWelcome'>Elevate your look with authentic thrift finds from local second-hand sellers and enjoy hassle-free shopping.</p>
                <button className='Redirectbutton' onClick={()=>handleRedirect()}>Shop for products <FaArrowRight size={30} /></button>
            </div>
            <div className='backgroundHolder'>
                <img src={productIMage} alt='products'/>
            </div>
        </section>
        <section className='socials'>
            <div>
                <FaInstagram size={30}/>
                <p>pigagoodlook</p>
            </div>
            <div>
                <FaWhatsapp size={30} />
                <p>254745303896</p>
            </div>
        </section>
        <section id='Features'> 
            {
                features.map((item, i)=>{
                    return(
                        <FeatureContainers key={i} image={item.image} icon={item.image}  heading={item.heading} subheading={item.subheading} />
                    )
                })
            }
        </section>
        <section id='faqs' className="mt-8 md:mt-20 xl:mx-28  items-center mx-2">
            <h1 className="mb-0 md:mb-5 font-times text-[28px] md:text-[48px] font-[600] text-black text-center md:text-left">Frequently Asked Questions</h1>            
            <div className=" flex flex-col md:grid grid-cols-1 md:grid-cols-2 md:gap-x-5 gap-y-3 px-4 py-5 md:py-0">
                <div className='flex-1 flex flex-col gap-y-3'>
                    <div className='bg-white border border-gray-200 p-2.5 md:p-4 rounded-[14px]'>
                        <div className=' flex justify-between items-center'>
                            <span className='font-lato text-sm md:text-base font-[700]'>
                            How does Piga GoodLook work?
                            </span>
                        </div>
                        <div id='smallHeading' className="pt-2 text-grey_600 text-[13px] md:text-sm font-inter">
                        We are a platform that helps thrift sellers to get their merchandise to people within or beyond their reach. We give them a portal where they can upload their products with all details and we help with the delivery of orders.
                        </div>
                    </div>
                    <div className='bg-white border border-gray-200 p-2.5 md:p-4 rounded-[14px]'>
                        <div className=' flex justify-between items-center'>
                            <span className='font-lato text-sm md:text-base font-[700]'>
                            What is our return policy?
                            </span>
                        </div>
                        <div id='smallHeading' className="pt-2 text-grey_600 text-[13px] md:text-sm font-inter">
                        We will exchange your items with items of prices belonging to the same range with what you bought.
                        </div>
                        
                    </div>
                    <div className='class=" bg-white border border-gray-200 p-2.5 md:p-4 rounded-[14px]"'>
                        <div className=' flex justify-between items-center'>
                            <span className='font-lato text-sm md:text-base font-[700]'>
                            How do we estimate the delivery fee?
                            </span>
                        </div>
                        <div id='smallHeading' className="pt-2 text-grey_600 text-[13px] md:text-sm font-inter">
                        Our delivery prices starts at 300ksh for deliveries within Nairobi Metropolitan and as you get out of the region, you will be charged according to how far you are. Our ultimate goal is to make purchases and deliveries to be easy and cheaper. Please allow location request when prompted about your location.
                        </div>
                        
                    </div>
                    <div className='class=" bg-white border border-gray-200 p-2.5 md:p-4 rounded-[14px]"'>
                        <div className=' flex justify-between items-center'>
                            <span className='font-lato text-sm md:text-base font-[700]'>
                            How can you partner with us as a seller?
                            </span>
                        </div>
                        <div id='smallHeading' className="pt-2 text-grey_600 text-[13px] md:text-sm font-inter">
                        Call us through the number 0745303896 or email us at pigagoodlook@gmail.com. Welcome partner.
                        </div>
                        
                    </div>
                </div>
                <div className='flex-1 flex flex-col gap-y-3'>
                    <div className='class=" bg-white border border-gray-200 p-2.5 md:p-4 rounded-[14px]"'>
                        <div className=' flex justify-between items-center'>
                            <span className='font-lato text-sm md:text-base font-[700]'>
                            What if my package doesn't get to me?
                            </span>
                        </div>
                        <div id='smallHeading' className="pt-2 text-grey_600 text-[13px] md:text-sm font-inter">
                        We have a good customer support department, don't feel shy to talk to us and let us handle all your needs. Through our socials @pigagoodlook or email pigagoodlook@gmail.com.
                        </div>
                    </div>
                    <div className='bg-white border border-gray-200 p-2.5 md:p-4 rounded-[14px]'>
                        <div className=' flex justify-between items-center'>
                            <span className='font-lato text-sm md:text-base font-[700]'>
                            What items we deal in?
                            </span>
                        </div>
                        <div id='smallHeading' className="pt-2 text-grey_600 text-[13px] md:text-sm font-inter">
                        Our main focus is thrift shoes, jackets and bags, but with time we will bring you more items.
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px'}}>
            <button className='Redirectbutton' onClick={()=>handleRedirect()}>Shop for products <FaArrowRight size={30} /></button>
        </div>
        <Footer />
    </main>
   )
}