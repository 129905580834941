import React, { useEffect, useState } from "react";
import { Header } from "../features/Header";
import './Shopping.css';
import { FaCartPlus } from "react-icons/fa6";
import { IoArrowBackCircle } from "react-icons/io5";
import axios from "axios";
import { Select } from "../features/Select";
// import {  toast } from 'react-toastify';

export const Shopping = ({setCart, cart})=>{
    const [product, setProduct] = useState(true);
    const [type, setItem] = useState('Shoes');
    const [products, setProducts] = useState([]);
    const [display, setDisplay]=useState({});

    const url = 'https://node-api.pigagoodlook.com/api/';

    useEffect(()=>{
    },[type])

    // const dropDownOptions = type === 'Shoe' ? euShoeSizes : clothingSizes;

    useEffect(()=>{
        axios.get(url + 'getAllPostedProducts')
        .then((res)=>{
            setProducts(res.data);
        }).catch((err)=>{
            if(err.code.includes('ERR_NETWORK')){
                return null;
            }
            return null;
        })
    }, [type])

    const addToCart = (item) => {
        const itemExists = cart.some(items => items.id === item.id);
        if(!itemExists){
            setCart([...cart, item])
        }
    }

    return(
        <div className="ShopContainer">
            <Header cart={cart}/>
            <div className="ProductContent">
                    {
                        product ? 

                        <div className="">
                            <div className="Filters">
                                <Select name="Item" setValue={setItem} dropDown={['Jackets', "Shoes", "Bags"]} color='#fff' font='black'/>
                                {/* {
                                    <Select name="Size" setValue={setSize} dropDown={dropDownOptions} color='#fff' font='black'/>
                                } */}
                            </div>
                            <div className="productListedContainer">
                                {
                                    products.map((item, i)=>{
                                        if(item.type === type){
                                            return(
                                                <div className="productListed" key={i}>
                                                    <img onClick={()=>{setProduct(false); setDisplay(item)}} src={url + item.image1} alt={item.name}/>
                                                    <div className="productDetails">
                                                        <div>
                                                            <p>{item.name}</p>
                                                            <p>{item.size}</p>
                                                        </div>
                                                        <div>
                                                            <p>{item.price}/=</p>
                                                            <FaCartPlus onClick={()=>{addToCart(item)}} style={{cursor: 'pointer'}} size={30}/>
                                                        </div>
                                                    </div> 
                                                </div>
                                            )
                                        }

                                        return null;  
                                    })
                                }
                            </div>
                        </div>

                        :

                        <div className="ProductView">
                            <div id="back"><IoArrowBackCircle onClick={()=>{setProduct(true)}} style={{cursor: 'pointer'}} size={30}/></div>
                            <div className="ImagesHolder">
                                <img src={url + display.image1} alt="Pic"/>
                                <img src={url + display.image2} alt="Pic"/>
                                <img src={url + display.image3} alt="Pic"/>
                            </div>
                            <div className="InfoAction">
                                <p>{display.name}</p>
                                <p>{display.size}</p>
                                <p>{display.gender}</p>
                                <div>
                                    <FaCartPlus onClick={()=>{addToCart(display)}} style={{cursor: 'pointer'}} size={30}/>
                                </div>
                            </div>
                        </div>
                    }
            </div>
        </div>
    )
}
