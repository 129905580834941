import React, {useState} from 'react';
import { LogIn } from '../features/LogIn';
import { Dashboard } from './Dashboard';

export const Admin = ()=>{
    const [logIn ,setLogIn] = useState(false);
    const token = localStorage.getItem('token');

    return(
        <div>
            {logIn || token
            
            ?
            
            <Dashboard />

            :

            <LogIn setLogIn={setLogIn}/>

            }
        </div>   
    )
}