import React, { useEffect, useState } from "react";
import { Header } from "../features/Header";
import './OrdersContainer.css';
import axios from "axios";
import { toast } from "react-toastify";

export const Orders =()=>{
    const [orderList, setOrderList]=useState([]);

    const url = `https://node-api.pigagoodlook.com/api/`;

    const handleOrderStatus = (id)=>{
        const confirm = window.confirm("Are you sure you have sent this item?")

        if(confirm){
            axios.get(url + 'updateOrderSent/' + id)
            .catch((err)=>{
                if(err.request.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('superadmin')
                    window.location.pathname = '/superadmin'
                }
                if(err.code.includes('ERR_NETWORK')){
                    toast.error('You are offline. Check you network and try again');
                }else{
                    toast.error("Error changing user setting");
                }
            })
        }
    }

    useEffect(()=>{
        axios.get(url + 'orderNotSent')
        .then((response)=>{setOrderList(response.data.result)
            console.log(response.data.result)
        }).catch((err)=>{
            if(err.request.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('superadmin')
                window.location.pathname = '/superadmin'
            }
            if(err.code.includes('ERR_NETWORK')){
                toast.error('You are offline. Check you network and try again');
            }else{
                toast.error("Error changing user setting");
            }
        })
    })

    return(
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#f6f6ff'}}>
            <Header />
            <div className="OrderContainer">
                <div className="OrderProductContainer">
                    <div>
                        {
                            orderList.map((item, i)=>{
                                const product = JSON.parse(item.products);
                                console.log(Array.isArray(product))
                                return(
                                    <div key={i} className="OrderProducts">
                                        <div className="ImageHolder">
                                            {
                                                product.map((product)=>{
                                                    return(
                                                        <div style={{display: 'flex'}} id="img">
                                                            <img src={url + product.image1} alt="Pic"/>
                                                            <p>{product.user_details.phoneNumber}</p>   
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div style={{width: '100%', display: 'block', padding: '10px'}}>
                                            <p>{item.phone_number}</p>
                                            <p>{item.town}</p>
                                        </div>
                                        <button onClick={()=>{handleOrderStatus(item.order_id)}}>Order sent</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                    </div>
                <div className="RecepientInfoContainer">
                    <div className="RecepientInfo">
                        <p>Number</p>
                        <p>Status</p>
                    </div>
                    <div className="RecepientInfo">
                        <p>Number</p>
                        <p>Status</p>
                    </div>
                    <div className="RecepientInfo">
                        <p>Number</p>
                        <p>Status</p>
                    </div>
                </div>
            </div>
        </div>
    )
}