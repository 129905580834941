import {
    Outlet,
    Navigate
} from 'react-router-dom'

const PrivateRoutes = () => {
    const admin = localStorage.getItem('admin');
    
    let auth = { 'token': admin }
    return (
        auth.token ?
            <Outlet /> :
            <Navigate to="/admin" />
    )
}

export default PrivateRoutes
