import React, {useState} from "react";
import { useLocation, Link } from "react-router-dom";
import './Header.css';
import Logo from '../assets/images/logo.png';
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { IoCartSharp } from "react-icons/io5";
import { IoHome } from "react-icons/io5";


export const Header = ({cart}) =>{
    const location = useLocation();
    const menuItems = ['Features','About','FAQs'];    
    const [displayMenu, setDisplayMenu]=useState("none");
    const dashboardItems = ['Upload', "Uploaded", "Payments"];
    const superadmin = ['Users','Products', 'Orders'];
    const shopping = ['Cart']

    const handleMenuClick = ()=>{
        setDisplayMenu('block');
    }

    function handleNavigation(item) {
        if (item === 'About') {
            window.location.href = `/${item.toLowerCase()}`;
        } else if(item === 'Features'){
            window.location.href = `#Features`;
        }else{
            window.location.href = `#faqs`;
        }
        setDisplayMenu('none')
    }

    function handleDashboardNavigation(item){
        window.location.href = `/admin/${item.toLowerCase()}`;
    }

    function handleAdminDashboardNavigation(item){
        window.location.href = `/superadmin/${item.toLowerCase()}`;
    }

    const handleMenuCloseClick = () =>{
        setDisplayMenu('none')
    }

    if(location.pathname.startsWith('/admin')){
        return(
            <header className='HeaderContainer'>
                <img style={{cursor: 'pointer'}} onClick={()=>{window.location.href = '/admin'}} src={Logo} alt="logo"/>
                <div className="mobileMenuContainer">
                    <IoMenu onClick={()=>{handleMenuClick()}} style={{cursor: 'pointer'}} size={70}/>
                </div>
                <nav className="mobileMenuList" style={{display: displayMenu}}>
                    <IoClose onClick={()=>{handleMenuCloseClick()}} size={30} style={{position: 'relative', left: 100, top: 10, cursor: 'pointer'}}/>
                    {
                        dashboardItems.map((item)=>{
                            return(
                                <p style={{cursor: 'pointer', marginTop: 10, marginBottom: 10, textAlign: 'left', paddingLeft: 15}} 
                                onClick={()=>{handleDashboardNavigation(item)}}  key={item}>{item}</p>
                            )
                        })
                    }
                </nav>
                <nav className="MenuContainer">
                    {
                        dashboardItems.map((item)=>{
                            return(
                                <p style={{cursor: 'pointer'}} onClick={()=>{handleDashboardNavigation(item)}} key={item}>{item}</p>
                            )
                        })
                    }
                </nav>
            </header>
        )
    }else if (location.pathname.startsWith('/shopping') || location.pathname.startsWith('/cart')){
        return(
            <header className='HeaderContainer'>
                <img onClick={()=>{window.location.href = `/shopping`;}} src={Logo} alt="logo"/>
                <nav className="shoppingContainer">
                    {
                        shopping.map((item)=>{
                            if(item === 'Cart'){
                                return(
                                    <nav key={item}>
                                        <p><Link to='/cart'><IoCartSharp style={{cursor: 'pointer'}} size={30}/></Link></p>
                                        <small id="small">{cart.length}</small>
                                    </nav>
                            )
                            }
                            return(
                                <p style={{cursor: 'pointer', marginTop: 10, marginBottom: 10, textAlign: 'left', paddingLeft: 15}} 
                                key={item}>{item}</p>
                            )
                        })
                    }
                </nav>
                <nav className="MenuContainer">
                    {
                        shopping.map((item)=>{
                            if(item === 'Cart'){
                                return(
                                <nav key={item}>
                                   <p style={{position: "relative"}}><Link to='/cart'><IoCartSharp style={{cursor: 'pointer'}} size={30}/></Link></p>
                                    <small id="small">{cart.length}</small>
                                </nav>
                                )
                            }
                            return(
                                <p style={{cursor: 'pointer'}} key={item}>{item}</p>
                            )
                        })
                    }
                </nav>
            </header>
        )
    }else if(location.pathname.startsWith('/super')){
        return(
            <header className='HeaderContainer'>
                <img src={Logo} alt="logo" onClick={() => { window.location.href = '/superadmin' }}/>
                <div className="mobileMenuContainer">
                    <IoMenu onClick={()=>{handleMenuClick()}} style={{cursor: 'pointer'}} size={70}/>
                </div>
                <nav className="mobileMenuList" style={{display: displayMenu}}>
                    <IoClose onClick={()=>{handleMenuCloseClick()}} size={30} style={{position: 'relative', left: 100, top: 10, cursor: 'pointer'}}/>
                    {
                        superadmin.map((item)=>{
                            return(
                                <p style={{cursor: 'pointer', marginTop: 10, marginBottom: 10, textAlign: 'left', paddingLeft: 15}} 
                                onClick={()=>{handleAdminDashboardNavigation(item)}}  key={item}>{item}</p>
                            )
                        })
                    }
                </nav>
                <nav className="MenuContainer">
                    {
                        superadmin.map((item)=>{
                            return(
                                <p style={{cursor: 'pointer'}} onClick={()=>{handleAdminDashboardNavigation(item)}} key={item}>{item}</p>
                            )
                        })
                    }
                </nav>
            </header>
        )
    }else if(location.pathname.startsWith('/about')){
        return(
            <header className='HeaderContainer'>
                <img src={Logo} alt="logo" onClick={() => { window.location.href = '/' }}/>
                <div className="mobileMenuContainer">
                    <IoMenu onClick={()=>{handleMenuClick()}} style={{cursor: 'pointer'}} size={70}/>
                </div>
                <nav className="mobileMenuList" style={{display: displayMenu, alignItems: 'center'}}>
                    <IoClose onClick={()=>{handleMenuCloseClick()}} size={30} style={{position: 'relative', left: 100, top: 10, cursor: 'pointer'}}/>
                    <p onClick={()=>{window.location.href = `/`;}} style={{height: '50px',display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '10px', cursor:'pointer'}}><IoHome size={30}/> Home</p>
                </nav>
                <nav className="MenuContainer">
                    <IoHome onClick={()=>{window.location.href = `/`;}} size={40}/>
                </nav>
            </header>
        )
    }else{
        return(
            <header className='HeaderContainer'>
                <img src={Logo} alt="logo"/>
                <div className="mobileMenuContainer">
                    <IoMenu onClick={()=>{handleMenuClick()}} style={{cursor: 'pointer'}} size={70}/>
                </div>
                <nav className="mobileMenuList" style={{display: displayMenu}}>
                    <IoClose onClick={()=>{handleMenuCloseClick()}} size={30} style={{position: 'relative', left: 100, top: 10, cursor: 'pointer'}}/>
                    {
                        menuItems.map((item)=>{
                            return(
                                <p style={{cursor: 'pointer', marginTop: 10, marginBottom: 10, textAlign: 'left', paddingLeft: 15}} 
                                onClick={()=>{handleNavigation(item)}}  key={item}>{item}</p>
                            )
                        })
                    }
                </nav>
                <nav className="MenuContainer">
                    {
                        menuItems.map((item)=>{
                            return(
                                <p style={{cursor: 'pointer'}} onClick={()=>{handleNavigation(item)}} key={item}>{item}</p>
                            )
                        })
                    }
                </nav>
            </header>
        )
    }
}