import React, { useEffect, useState } from "react";
import { Header } from "../features/Header";
import './Payments.css';
import axios from "axios";

export const Payments = ()=>{
    const [balance, setBalance] = useState('');
    const [withdraw, setWithdraw] = useState([]);
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');

    useEffect(()=>{
        axios.get(`https://node-api.pigagoodlook.com/api/availableBalance/${user}`, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{setBalance(res.data.balance)})
        .catch((err)=>{
            if(err.request.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('admin');
                window.location.pathname = '/admin'
            }})
    }, [token, user])


    useEffect(()=>{
        axios.get(`https://node-api.pigagoodlook.com/api/withdrawals/${user}`, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        })
        .then((res)=>{setWithdraw(res.data.withdrawals)})
        .catch((err)=>{
            if(err.request.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('admin');
                window.location.pathname = '/admin'
            }
        })
    }, [token, user])
    
    return(
        <div className="PaymentsContainer">
            <Header />
            <div className="PaymentsInfo">
                <h2>Available Amount : {balance}</h2>
                <input placeholder="Enter amount to withdraw"/>
                <button>Withdraw</button>
            </div>
            <div className="Withdrawals">
                {
                    withdraw.map((item, i)=>{
                        const date = new Date(item.date);
                        return(
                            <div key={i}>
                                <p>{item.amount}</p>
                                <p>{date.toLocaleTimeString()}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}